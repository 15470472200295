<template>
  <div>
    <b-card
      class="position-relative main-card-padding mb-p-1 mobile-card-padding"
    >
      <div class="mb-2 justify-content-between align-items-center d-flex">
        <div class="d-flex align-items-center">
          <h4 class="mb-0">{{ $t("Account.AccountTreeView") }}</h4>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="search-filter d-flex align-items-center pb-0 mr-0">
            <label class="pr-1 mb-1">{{
              $t("userInvitations.SearchTitle")
            }}</label>
            <b-form-group class="next-child">
              <b-input-group class="height-100-child">
                <b-form-input
                  :placeholder="$t('userInvitations.Search')"
                  v-model="filterAccount"
                  ref="search_input"
                  @keyup.enter="selectIcon()"
                  @input="clickTxt()"
                ></b-form-input>
                <b-input-group-append
                  class="input-prefix-img search-clear curserPointer"
                >
                  <feather-icon icon="XIcon" @click="clearTxt" size="20" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
          <div
            class="AccountFlid data-device-w"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ACCOUNT_READ,
                subject: constants.PERMISSIONS_MODEL.ACCOUNT
              })
            "
          ></div>
        </div>
      </div>
      <Loader :show="show" />
      <dynamic-collapse
        v-if="!show && accountsList && accountsList.length && !isNoChildAccount"
        :data="accountsList"
        :getChildAccounts="getChildAccounts"
        :filterChange="filterAccount"
        :isParent="true"
      ></dynamic-collapse>
      <span v-if="isNoChildAccount">
        <h4>
          {{ $t("Account.NoAccountTitle") }}
        </h4>
        <h4
          v-if="
            isNoChildAccount &&
            ((checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.ACCOUNT
            }) &&
              accountType === 'DEALER') ||
              (checkAbility({
                action: constants.PERMISSIONS_ACTION.CONSUMER_ADD,
                subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
              }) &&
                accountType === 'CONSUMER'))
          "
        >
          {{ $t("Account.NoAccountSubTitle1") }}

          <b-link @click="redirectCreationPage">
            {{ $t("Account.NoAccountSubTitle2") }}</b-link
          >
          {{ $t("Account.NoAccountSubTitle3") }}
        </h4>
      </span>
      <div
        style="text-align: center"
        v-if="
          !show && !isNoChildAccount && (!accountsList || !accountsList.length)
        "
      >
        {{ $t("NoMatchingRecordsFound") }}
      </div>
    </b-card>
  </div>
</template>

<script>
import Loader from "@/layouts/components/Loader.vue";
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCollapse,
  BButton,
  BCardText,
  VBToggle,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend
} from "bootstrap-vue";
import DynamicCollapse from "./DynamicCollapse.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccountService from "@/libs/api/account-service";

import vSelect from "vue-select";
const searchTree = (nodes, searchTerm) => {
  const lowerCaseTerm = searchTerm.toLowerCase();

  // Check if a node matches the search term
  const matchesSearchTerm = (node) =>
    node.label.toLowerCase().includes(lowerCaseTerm);

  // Traverse the tree to find matching nodes with their immediate parent
  const traverse = (node, parentMatched) => {
    const isMatched = matchesSearchTerm(node);
    let newNode = null;

    if (isMatched || parentMatched) {
      newNode = { ...node };
      if (isMatched) {
        newNode.nearestSearch = true;
      }
      if (newNode.children) {
        newNode.children = newNode.children
          .map((child) => traverse(child, isMatched || parentMatched))
          .filter(Boolean);
      }
    } else if (node.children) {
      const matchedChildren = node.children
        .map((child) => traverse(child, isMatched || parentMatched))
        .filter(Boolean);
      if (matchedChildren.length > 0) {
        newNode = { ...node, children: matchedChildren };
      }
    }

    return newNode;
  };

  const matches = nodes.map((node) => traverse(node, false)).filter(Boolean);

  return matches;
};

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
    BButton,
    BCardText,
    BRow,
    BLink,
    DynamicCollapse,
    vSelect,
    BFormInput,
    BCol,

    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    Loader
  },
  data() {
    return {
      option: ["10", "20", "30"],
      accountsList: [],
      oldAccountsList: [],
      isNoChildAccount: false,
      accountType: localStorage.getItem("USER_ACCOUNT_TYPE"),
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: 1,
      filterAccount: "",
      show: true
    };
  },
  directives: {
    "b-toggle": VBToggle
  },
  mounted() {
    this.getChildAccounts();
  },
  watch: {
    filterAccount(val) {
      // this.getChildAccounts();
      const q = searchTree(this.oldAccountsList, val);
      this.accountsList = [];
      this.accountsList = q;

      // console.log(JSON.stringify(resultTree, null, 2));
    }
  },
  methods: {
    redirectCreationPage() {
      if (localStorage.getItem("USER_ACCOUNT_TYPE") === "DEALER") {
        this.$router.push({ name: "dealer-creation" });
      } else if (localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER") {
        this.$router.push({ name: "consumer-creation" });
      }
    },
    async getChildAccounts() {
      try {
        this.show = true;
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: this.filterAccount || "",
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });
        this.show = false;
        if (response && response.data) {
          this.isNoChildAccount =
            response.data &&
            response.data.length &&
            response.data[0].children &&
            response.data[0].children.length > 1
              ? false
              : true;
          this.accountsList =
            response.data && response.data.length
              ? response.data[0].children
              : [];
          this.oldAccountsList =
            response.data && response.data.length
              ? response.data[0].children
              : [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    selectIcon(field) {},

    clickTxt() {},
    clearTxt() {
      this.filterAccount = "";
      this.accountsList = this.oldAccountsList;
    }

    // Function to search the tree and build the new structure

    // Example usage:
  }
};
</script>

<style scoped>
/* Add any styles specific to your component here */
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
