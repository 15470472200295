<template>
  <div class="tab-list-outer-parent">
    <div
      v-for="item in accountData"
      :key="item.id"
      class="tab-list-parent"
      :class="item.children && item.children.length ? 'sub-parent' : ''"
    >
      <div role="tablist">
        <b-card class="mb-0 tab-main-w" no-body
          ><span
            class="d-flex align-items-center justify-content-between pl-1 pr-1 child-bg"
            style="min-height: 50px; display: inline-block"
          >
            <div class="label-text">
              <feather-icon
                v-if="item.children && item.children.length"
                class="icon-margin PlusSquareIcon"
                v-b-toggle="'collapse-' + item.id"
                :icon="
                  collapseStates[item.id] ? 'MinusSquareIcon' : 'PlusSquareIcon'
                "
                size="20"
                @click="clickOnIcon(item)"
              />
              <!-- <feather-icon
                v-if="item.children && item.children.length"
                class="icon-margin MinusSquareIcon"
                v-b-toggle="'collapse-' + item.id"
                icon="MinusSquareIcon"
                size="20"
                @click="clickOnIcon('minus')"
              /> -->
              <DealerUser class="icon-margin" v-if="item.type === 'DEALER'" />
              <ConsumerUser class="icon-margin" v-else />
              <span>{{ item.label }}</span>
            </div>
            <div
              class="text-left list-icon d-flex align-items-center"
              v-if="item.id !== getCurrentAccount()"
            >
              <span
                v-b-tooltip.hover.top="$t('tooTip.Devices')"
                v-if="item.type === 'DEALER'"
              >
                <b-link
                  target="_blank"
                  :to="{
                    name: 'devices-list',
                    query: {
                      owner_account_id: item.id
                    }
                  }"
                  v-if="
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.READ,
                      subject: constants.PERMISSIONS_MODEL.DEVICES
                    })
                  "
                >
                  <feather-icon
                    icon="CpuIcon"
                    class="text-primary action-icon"
                    size="18"
                    style="position: relative; cursor: pointer"
                /></b-link>
              </span>
              <span v-b-tooltip.hover.top="$t('tooTip.Units')">
                <b-link
                  target="_blank"
                  :to="{
                    name: 'unit-map',
                    query: {
                      account_id: item.id
                    }
                  }"
                  v-if="
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.READ,
                      subject: constants.PERMISSIONS_MODEL.UNITS
                    })
                  "
                >
                  <feather-icon
                    icon="ServerIcon"
                    class="text-primary action-icon"
                    size="18"
                    style="position: relative; cursor: pointer"
                /></b-link>
              </span>
              <span v-b-tooltip.hover.top="$t('tooTip.update')">
                <b-link
                  target="_blank"
                  @click="consumerEdit(item)"
                  v-if="
                    item.type === 'CONSUMER' &&
                    (!isParent
                      ? checkAbility({
                          action:
                            constants.PERMISSIONS_ACTION
                              .UPDATE_CONSUMER_CHILD_ACCOUNT,
                          subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                        })
                      : checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                          subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                        }))
                  "
                >
                  <feather-icon
                    v-b-tooltip.hover.top="$t('tooTip.update')"
                    icon="EditIcon"
                    class="text-primary action-icon cursor-pointe"
                    size="18"
                  />
                </b-link>

                <b-link
                  target="_blank"
                  @click="dealerEdit(item)"
                  v-if="
                    item.type === 'DEALER' &&
                    (!isParent
                      ? checkAbility({
                          action: constants.PERMISSIONS_ACTION.ACCOUNT_UPDATE,
                          subject: constants.PERMISSIONS_MODEL.ACCOUNT
                        })
                      : checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE,
                          subject: constants.PERMISSIONS_MODEL.ACCOUNT
                        }))
                  "
                >
                  <feather-icon
                    v-b-tooltip.hover.top="$t('tooTip.update')"
                    icon="EditIcon"
                    class="text-primary action-icon cursor-pointe"
                    size="18"
                  />
                </b-link>
              </span>
              <b-dropdown
                :text="
                  item.status
                    ? $t(`device.StatusList.${item.status}`)
                    : $t('device.StatusList.ACTIVE')
                "
                id="dropdown-dropright"
                v-if="
                  item.type === 'CONSUMER'
                    ? !isParent
                      ? checkAbility({
                          action:
                            constants.PERMISSIONS_ACTION
                              .UPDATE_CONSUMER_CHILD_ACCOUNT,
                          subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                        })
                      : checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE_CONSUMER,
                          subject: constants.PERMISSIONS_MODEL.CONSUMER_ACCOUNT
                        })
                    : item.type === 'DEALER' &&
                      (!isParent
                        ? checkAbility({
                            action: constants.PERMISSIONS_ACTION.ACCOUNT_UPDATE,
                            subject: constants.PERMISSIONS_MODEL.ACCOUNT
                          })
                        : checkAbility({
                            action: constants.PERMISSIONS_ACTION.UPDATE,
                            subject: constants.PERMISSIONS_MODEL.ACCOUNT
                          }))
                "
                v-model="item.status"
                :variant="
                  item.status && item.status == 'INACTIVE'
                    ? 'danger'
                    : item.status && item.status == 'BLOCKED'
                    ? 'warning'
                    : 'primary'
                "
                class="m-l-0 btn-min-width status-btn"
                size="sm"
              >
                <b-dropdown-item
                  @click="updateStatus(item, 'ACTIVE', isParent)"
                  class="btn-min-width"
                  >{{ $t("active") }}</b-dropdown-item
                >
                <b-dropdown-item
                  @click="updateStatus(item, 'INACTIVE', isParent)"
                  class="btn-min-width"
                  >{{ $t("inactive") }}</b-dropdown-item
                >
                <b-dropdown-item
                  @click="updateStatus(item, 'BLOCKED', isParent)"
                  class="btn-min-width"
                  >{{ $t("blocked") }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </span>

          <b-collapse
            :id="'collapse-' + item.id"
            class="mt-0 pl-2 pr-0 child-body"
            v-model="collapseStates[item.id]"
            v-if="item.children && item.children.length"
          >
            <b-card no-body class="children">
              <dynamic-collapse
                :data="item.children"
                :isParent="false"
                :getChildAccounts="getChildAccountsCall"
              ></dynamic-collapse>
            </b-card>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCollapse,
  BButton,
  BCardText,
  VBToggle,
  VBTooltip,
  BLink,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import DealerUser from "@/assets/images/dealer-user.svg";
import ConsumerUser from "@/assets/images/consumer-user.svg";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccountService from "@/libs/api/account-service";

export default {
  name: "DynamicCollapse",
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCollapse,
    BButton,
    BCardText,
    FeatherIcon,
    ConsumerUser,
    DealerUser,
    BDropdown,
    BDropdownItem,
    BLink
  },
  data() {
    return {
      text: "This is some text for the accordion panels",
      accountData: this.data || [],
      collapseStates: {}
    };
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    filterChange: {
      type: String,
      required: false
    },
    getChildAccounts: {
      type: Function
    },
    isParent: {
      type: Boolean,
      default: true
    }
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip
  },
  mounted() {
    setTimeout(() => {
      let flatData = [];
      this.data.forEach((node) => {
        flatData = flatData.concat(this.extractAllNodes(node));
      });
      flatData.forEach((element) => {
        this.collapseStates[element.id] = false;
      });
    }, 500);
  },
  watch: {
    data() {
      this.accountData = this.data;
    },
    filterChange(val) {
      if (val) {
        let flatData = [];
        this.data.forEach((node) => {
          flatData = flatData.concat(this.extractAllNodes(node));
        });
        flatData.forEach((element) => {
          this.collapseStates[element.id] = true;
        });
      } else {
        let flatData = [];
        this.data.forEach((node) => {
          flatData = flatData.concat(this.extractAllNodes(node));
        });
        flatData.forEach((element) => {
          this.collapseStates[element.id] = false;
        });
      }

      // Object.keys(this.collapseStates).forEach((element) => {
      //   this.collapseStates[element] = true;
      // });
      // let flatData = [];
      // this.data.forEach((node) => {
      //   flatData = flatData.concat(this.extractAllNodes(node));
      // });
      // flatData.forEach((element) => {
      //   element.is_click = false;
      //   this.collapseStates[element.id] = true;
      //   this.clickOnIcon(element);
      // });
    }
  },
  methods: {
    getCurrentAccount() {
      return localStorage.getItem("USER_ACCOUNT_ID");
    },
    extractAllNodes(node) {
      let allNodes = [{ ...node }];
      delete allNodes[0].children; // Remove children property from the current node

      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          allNodes = allNodes.concat(this.extractAllNodes(child));
        });
      }
      return allNodes;
    },
    extractChildren(node) {
      let children = [];
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          children.push(child);
          children = children.concat(this.extractChildren(child));
        });
      }
      return children;
    },

    async updateStatus(account, status, isParent) {
      try {
        this.show = true;
        let response = !isParent
          ? account.type === "DEALER"
            ? await new AccountService().updateDealerSubAccountStatus({
                account_id: account.id,
                status: status,
                type: account.type
              })
            : await new AccountService().updateConsumerSubAccountStatus({
                account_id: account.id,
                status: status,
                type: account.type
              })
          : account.type === "DEALER"
          ? await new AccountService().updateDealerAccountStatus({
              account_id: account.id,
              status: status,
              type: account.type
            })
          : await new AccountService().updateConsumerAccountStatus({
              account_id: account.id,
              status: status,
              type: account.type
            });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("SubAccounts.AccountStatustitle"),
              text: this.$t("SubAccounts.AccountStatusText"),
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.getChildAccountsCall();
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    clickOnIcon(e) {
      const toggleClick = (item) => {
        if (item.id === e.id) {
          return {
            ...item,
            is_click: !item.is_click
          };
        }
        return item;
      };

      const traverseAndToggle = (items) => {
        return items.map((item) => {
          if (item.id === e.id) {
            return toggleClick(item);
          }
          if (item.children) {
            return {
              ...item,
              children: traverseAndToggle(item.children)
            };
          }
          return item;
        });
      };

      this.accountData = traverseAndToggle(this.accountData);
      // this.$emit("clickOnIcon", e);
    },
    getChildAccountsCall() {
      this.getChildAccounts();
    },
    dealerEdit(item) {
      const routeUrl = this.$router.resolve({
        name: !this.isParent
          ? "sub-account-update-details"
          : "account-update-details",
        query: {
          id: item.id,
          isEdit: true,
          account_id: item.id,
          rd: "cl"
        }
      }).href;

      // Open the URL in a new tab
      window.open(routeUrl, "_blank");
    },
    consumerEdit(item) {
      const routeUrl = this.$router.resolve({
        name: !this.isParent
          ? "consumer-sub-account-update-details"
          : "consumer-account-update-details",
        query: {
          id: item.id,
          isEdit: true,
          account_id: item.id,
          rd: "cl"
        }
      }).href;

      // Open the URL in a new tab
      window.open(routeUrl, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
/* Add any styles specific to your component here */
.child-body {
  margin-bottom: 0px;
  .card-body {
    padding: 0;
  }
}
.tab-list-parent {
  margin-bottom: 10px;
  background-color: var(--white);
  border-radius: 9px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.tab-list-outer-parent {
  margin-top: 10px;
}
.child-body {
  .tab-list-parent .child-bg {
    background-color: #ebecee;
    border-radius: 9px;
    // padding: 6px 0px;
  }
}
.card.children {
  margin-bottom: 10px;
}
.sub-parent .tab-list-parent:last-child {
  margin-bottom: 0px;
}
.sub-parent .card {
  background-color: transparent;
}
.icon-margin {
  margin-left: 5px;
  margin-right: 3px;
}
.label-text {
  color: var(--black);
  font-weight: 500;
  display: flex;
  align-items: center;
  span {
    margin-left: 4px;
  }
}
.status-btn {
  margin-left: 10px;
  margin-right: 10px;
  // min-width: 120px;
}
.dark-layout .card {
  background-color: var(--dark-bg);
  .label-text {
    color: var(--white);
  }
  box-shadow: 0 4px 24px 0 rgba(#22292f, 0.24);
  .child-body {
    .tab-list-parent .child-bg {
      background-color: #343d55;

      // padding: 6px 0px;
    }
  }
}
</style>
